import type { Theme } from "theme-ui";

import { themed } from "./themed";
import { buttons } from "./variants/buttons";
import { links } from "./variants/links";
import { borders } from "./variants/borders";
import { text } from "./variants/text";

export const makeTheme = <T extends Theme>(t: T) => t;

const typographyTheme = makeTheme({
  useCustomProperties: true,
  config: {
    initialColorModeName: "light",
  },
  colors: {
    text: "hsl(10, 20%, 20%)",
    background: "hsl(10, 10%, 98%)",
    primary: "hsl(10, 80%, 50%)",
    secondary: "hsl(10, 60%, 50%)",
    accent: "hsl(250, 60%, 30%)",
    highlightCode: "hsl(10, 40%, 90%)",
    muted: "hsl(10, 20%, 94%)",
    modes: {
      dark: {
        text: "hsl(210, 50%, 96%)",
        background: "hsl(230, 25%, 18%)",
        primary: "hsl(260, 100%, 80%)",
        secondary: "hsl(290, 100%, 80%)",
        accent: "hsl(290, 100%, 80%)",
        muted: "hsla(230, 20%, 0%, 20%)",
        highlightCode: "hsla(260, 20%, 40%, 20%)",
      },
    },
  },
  fonts: {
    body: "'Alegreya Sans', 'Gill Sans', Arial, sans-serif",
    heading: "'Alegreya Sans', 'Gill Sans', Arial, sans-serif",
    monospace: "'Consolas', monospace",
  },
  fontSizes: [
    "0.422em",
    "0.563em",
    "0.75em",
    "1em",
    "1.333em",
    "1.777em",
    "2.369em",
    "3.157em",
    "4.209em",
  ],
  space: [
    0,
    "calc(var(--rhythm) / 8)",
    "calc(var(--rhythm) / 4)",
    "calc(var(--rhythm) / 2)",
    "var(--rhythm)",
    "calc(var(--rhythm) * 2)",
    "calc(var(--rhythm) * 3)",
    "calc(var(--rhythm) * 4)",
    "calc(var(--rhythm) * 8)",
    "calc(var(--rhythm) * 16)",
  ],
  styles: {
    root: {
      fontFamily: "body",
      fontSize: ["18px", "20px"],
      lineHeight: [1.3, 1.4],
      "--rhythm": ["calc(1.3 * 18px)", "calc(1.4 * 20px)"],
    },
    ...themed,
  },
  // Variants.
  borders,
  links,
  buttons,
  text,
});

export type TypographyTheme = typeof typographyTheme;

export default typographyTheme;
