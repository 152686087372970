import type { Theme } from "theme-ui";

// Color and link variants change only color related properties.
// You still need a basic component with default styles.
export const links: Theme["links"] = {
  text: {
    color: "primary",
    textDecoration: "underline",
    outline: "2px solid transparent",
    "&:hover, &:focus": {
      bg: "primary",
      color: "background",
      textDecoration: "none",
      boxShadow: (theme) => `0 0 0 2px ${theme.colors?.primary}`,
    },
  },
  // This overrides the text variant.
  ui: {
    color: "primary",
    bg: "transparent",
    textDecoration: "none",
    "&:hover": {
      bg: "transparent",
      color: "secondary",
      boxShadow: "none",
    },
    "&:focus": {
      bg: "transparent",
      color: "secondary",
      boxShadow: (theme) => `0 0 0 2px ${theme.colors?.secondary}`,
      outline: "2px solid transparent",
    },
  },
};
