import type { ThemeStyles, ThemeUIStyleObject } from "theme-ui";

const prismStyles: ThemeUIStyleObject = {
  [[
    ".comment",
    ".prolog",
    ".doctype",
    ".cdata",
    ".punctuation",
    ".operator",
    ".entity",
    ".url",
  ].join(",")]: {
    color: "gray",
  },
  ".comment": {
    fontStyle: "italic",
  },
  [[
    ".property",
    ".tag",
    ".boolean",
    ".number",
    ".constant",
    ".symbol",
    ".deleted",
    ".function",
    ".class-name",
    ".regex",
    ".important",
    ".variable",
  ].join(",")]: {
    color: "accent",
  },
  [[".atrule", ".attr-value", ".keyword"].join(",")]: {
    color: "primary",
  },
  [[
    ".selector",
    ".attr-name",
    ".string",
    ".char",
    ".builtin",
    ".inserted",
  ].join(",")]: {
    color: "secondary",
  },
  ".gatsby-highlight-code-line": {
    backgroundColor: "highlightCode",
    display: "block",
    marginRight: "-1em",
    marginLeft: "-1em",
    paddingRight: "1em",
    paddingLeft: "0.75em",
    borderLeft: (theme) => `0.25em solid ${theme.colors?.accent}`,
  },
};

export const themed: ThemeStyles = {
  h1: {
    variant: "heading",
    fontSize: 6,
    mt: 5,
    mb: 4,
  },
  h2: {
    variant: "heading",
    fontSize: 5,
    mt: 5,
    mb: 4,
  },
  h3: {
    variant: "heading",
    fontSize: 4,
    mt: 5,
    mb: 4,
  },
  h4: {
    variant: "heading",
    fontSize: 3,
    mt: 4,
    mb: 3,
  },
  h5: {
    variant: "heading",
    fontSize: 3,
    mt: 4,
    mb: 3,
  },
  h6: {
    variant: "heading",
    fontSize: 3,
    mt: 4,
    mb: 3,
  },
  p: {
    mt: 0,
    mb: 4,
  },
  ul: {
    pl: 4,
    m: 0,
    mb: 4,
    "& ul, & ol": {
      m: 0,
    },
  },
  ol: {
    pl: 4,
    m: 0,
    mb: 4,
    "& ul, & ol": {
      m: 0,
    },
  },
  li: {
    m: 0,
  },
  pre: {
    fontFamily: "monospace",
    overflowX: "auto",
    borderRadius: "4px",
    bg: "muted",
    color: "text",
    mt: 0,
    mb: 4,
    p: 3,

    ...prismStyles,
    // code: {
    //   color: "inherit",
    // },
  },
  code: {
    fontFamily: "monospace",
    fontSize: "18px",
    // fontSize: "inherit",
  },
  a: {
    variant: "links.text",
  },
  img: {
    maxWidth: "100%",
    borderRadius: "4px",
    mb: 4,
  },
  table: {
    width: "100%",
    mb: 4,
    borderCollapse: "collapse",
    border: (theme) => `2px solid ${theme.colors?.text}`,
    "& tbody": {
      verticalAlign: "middle",
    },
    "& caption": {
      py: 3,
      px: 1,
    },
  },
  th: {
    bg: "muted",
    border: (theme) => `1px solid ${theme.colors?.text}`,
    py: 2,
    px: 3,
    lineHeight: 1.2,
  },
  td: {
    border: (theme) => `1px solid ${theme.colors?.text}`,
    py: 2,
    px: 3,
    textAlign: "center",
  },
  blockquote: {
    fontStyle: "italic",
    fontSize: 3,
    bg: "muted",
    m: 0,
    mb: 4,
    p: 4,
    borderLeft: (theme) => `4px solid ${theme.colors?.primary}`,
  },
};
