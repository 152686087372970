import type { Theme } from "theme-ui";

export const buttons: Theme["buttons"] = {
  primary: {
    color: "background",
    bg: "primary",
    "&:hover, &:focus": {
      bg: "secondary",
    },
    "&:focus": {
      boxShadow: (theme) => `0 0 0 2px ${theme.colors?.primary}`,
      outline: "2px solid transparent",
    },
  },
  transparent: {
    color: "primary",
    bg: "transparent",
    "&:hover, &:focus": {
      color: "background",
      bg: "secondary",
    },
    "&:focus": {
      boxShadow: (theme) => `0 0 0 2px ${theme.colors?.primary}`,
      outline: "2px solid transparent",
    },
  },
};
