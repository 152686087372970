import baseTheme, { makeTheme } from "./base-theme";

const theme = makeTheme({
  ...baseTheme,
  config: {
    initialColorModeName: "light",
    useColorSchemeMediaQuery: true,
  },
  colors: {
    ...baseTheme.colors,
    primary: "#9a260f",
    hexChar: "#484848",
    header: "hsla(10, 20%, 94%, 1)",
    searchInput: "hsla(10, 20%, 98%, 1)",
    scrollbar: "hsl(10 50% 50% / 1)",
    scrollbarSearch: "hsl(250 40% 70% / 1)",
    modes: {
      dark: {
        ...baseTheme.colors.modes.dark,
        primary: "#c6aafd",
        hexChar: "#b1adad",
        background: "#1b1e2e",
        muted: "#2e3142",
        highlightCode: "hsla(234, 22%, 12%, 1)",
        header: "hsla(234, 22%, 12%, 1)",
        searchInput: "#2e3142",
        scrollbar: "hsl(260 43% 71% / 1)",
        scrollbarSearch: "hsl(290 40% 80% / 1)",
      },
    },
  },
  fonts: {
    ...baseTheme.fonts,
    heading:
      "Source Serif Variable, 'Times New Roman', Times, Georgia, serif, sans-serif",
    body: "Source Serif Variable, 'Times New Roman', Times, Georgia, serif, sans-serif",
  },
  fontSizes: [
    "0.296em",
    "0.444em",
    "0.667em",
    "1em",
    "1.5em",
    "2.25em",
    "3.375em",
    "5.063em",
    "7.594em",
  ],
  text: {
    ...baseTheme.text,
    heading: {
      ...baseTheme.text?.heading,
      fontFamily: "heading",
      fontWeight: 600,
      lineHeight: 1.1,
      // width: "max-content",
      // borderBottom: theme => `1px solid ${theme.colors.text}`,
      // borderBottomLeftRadius: "4px",
      // borderBottomRightRadius: "7px",
    },
  },
  styles: {
    ...baseTheme.styles,
    root: {
      ...baseTheme.styles.root,
      fontSize: ["18px", "20px"],
      lineHeight: 1.4,
      "--rhythm": ["calc(1.4 * 18px)", "calc(1.4 * 20px)"],
      "*::-webkit-scrollbar-track, &::-webkit-scrollbar-track": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "10px",
        bg: "muted",
      },
      "&::-webkit-scrollbar, *::-webkit-scrollbar": {
        width: "12px",
        bg: "muted",
      },
      "&::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
        bg: "scrollbar",
      },
    },
    p: {
      ...baseTheme.styles.p,
      maxWidth: "calc(var(--rhythm)* 24)",
    },
    h1: {
      ...baseTheme.styles.h1,
      mt: 5,
      fontVariantLigatures: "common-ligatures contextual",
      fontFeatureSettings: '"kern", "liga", "clig", "calt", "swsh"',
      fontKerning: "normal",
      fontStyle: "italic",
    },
    // due to CSS grid not collapsing margins.
    h2: {
      ...baseTheme.styles.h2,
      mt: 4,
    },
    h3: {
      ...baseTheme.styles.h3,
      mt: 4,
    },
    code: {
      ...baseTheme.styles.code,
      mb: 4,
      bg: "muted",
      py: 3,
      px: 4,
    },
  },
});

export type MyThemeType = typeof theme;

export default theme;
